import ProjectTimeline from "../components/ProjectTimeline";

const Projects : React.FC = () =>{

    return(
        <div>
            <h2>Projects</h2>
            <ProjectTimeline />
        </div>
    );
};

export default Projects;