import React from 'react';
import '../styles/Footer.css';

const Footer: React.FC = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        <h3>PRAMUDITHA KARUNARATHNA</h3>
        <p>&copy; 2024 Pramuditha Karunarathna</p>
        <div className='footer-links-container'>
        <a href="https://x.com/shemil076">
            <img src="/assets/x.png" alt="" className='footer-links-img' />
          </a>
          <a href="https://www.linkedin.com/in/pramudithakarunarathna/">
            <img src="/assets/linkedin.png" alt="" className='footer-links-img' />
          </a>
          <a href="https://www.instagram.com/shemils__/">
            <img src="/assets/insta.png" alt="" className='footer-links-img' />
          </a>
          <a href="https://www.facebook.com/shemil076/">
            <img src="/assets/fb.png" alt="" className='footer-links-img' />
          </a>
          <a href="https://wa.me/94764259425">
            <img src="/assets/wa.png" alt="" className='footer-links-img' />
          </a>
          
        </div>
      </div>
    </footer>
  );
}

export default Footer;
