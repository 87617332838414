import '../styles/Home.css'
const Home : React.FC = () =>{

    return(
        <div>
            <div className='upper-section'>
            <div className='text-container'>
                <h1 className='main-text'>PRAMUDITHA KARUNARATHNA</h1>
                <p className='intro-para'>
                As a passionate software engineer and computer vision enthusiast, I thrive on crafting innovative solutions and pushing the boundaries of technology. With a strong foundation in software development and a keen interest in the latest advancements, I am dedicated to creating impactful and efficient applications. 
                </p>
            </div>
            {/* <div className='main-image-container'> */}
            <img className="main-img" src="/assets/Subject 2.png" alt="Main-Image" />
            {/* </div> */}
            </div>
        </div>
    );
};

export default Home;