import React from 'react';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import '../styles/ProjectTimeline.css'; // Custom CSS for additional styling

interface Project {
  title: string;
  year: string;
  description: string;
  image: string;
}

const projects: Project[] = [
  {
    title: "Shoe.zzyy Website",
    year: "2024",
    description: "A responsive web application with an admin console for managing shoes and a customer interface for viewing available shoes and tracking orders.",
    image: "/assets/shoezzyy.png"
  },
  {
    title: "Classic White Website",
    year: "2024",
    description: "An e-commerce application where customers can browse and place orders for a wide range of cosmetics.",
    image: "/assets/cw.png" 
  },
  {
    title: "Intelligent Waste Manager",
    year: "2024",
    description: "An iOS app that scans waste for real-time segregation, classifies it into categories, and provides sustainable waste management suggestions.",
    image: "/assets/fyp.png" 
  },
  {
    title: "Advancements in YOLO v8 for Enhanced Occluded Object Detection",
    year: "2024",
    description: "An image processing project that enhances YOLO v8 to classify occluded or partially visible objects.",
    image: "/assets/ObjectDetection.png" 
  },
  {
    title: "A Tourist Weather Utility App",
    year: "2023",
    description: "A tourist utility iOS weather app featuring dynamically changing animated backgrounds that reflect current weather conditions and transition between day and night.",
    image: "/assets/weatherApp.png"
  },
  {
    title: "ObSign",
    year: "2022",
    description: "An image processing application designed to assist verbally impaired primary school students in identifying objects and learning associated sign language through visual recognition and interactive features.",
    image: "/assets/blankAndroid2.png" // Replace with your image path
  },
  {
    title: "Goal In Website",
    year: "2021",
    description: "An e-commerce web application that enables users to browse products.",
    image: "/assets/goalIn.png" 
  },
  {
    title: "Foody Goody",
    year: "2019",
    description: "An Android mobile application that provides users with a variety of food recipes and cooking instructions.",
    image: "/assets/blankAndroid2.png" 
  },
  {
    title: "Cash Deposit Machine (CDM) Applcation",
    year: "2018",
    description: "An application for a Cash Deposit Machine that simplifies transactions by using QR codes for quick and efficient deposits.",
    image: "/assets/blankDeskTop2.png" 
  },
];

const ProjectTimeline: React.FC = () => {
  return (
    <VerticalTimeline     lineColor={'#E3E5E8'}>
      {projects.map((project, index) => (
        <VerticalTimelineElement
    contentArrowStyle={{ borderRight: '7px solid  rgb(74, 80, 86)' }}
          key={index}
          iconStyle={{ background: 'rgb(74, 80, 86)', color: '#fff' }}
          icon={<div className='date-on-icon'>
            {project.year}
          </div>}
        >
          <h3 className="vertical-timeline-element-title">{project.title}</h3>
          <img src={project.image} alt={project.title} className="project-image"/>
          <p>{project.description}</p>
        </VerticalTimelineElement>
      ))}
    </VerticalTimeline>
  );
};

export default ProjectTimeline;
