import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import '../styles/NavBar.css';

const Navbar: React.FC = () => {
  const [navActive, setNavActive] = useState<boolean>(false);

  const toggleNav = () => {
    setNavActive(!navActive);
  };

  const closeMenu = () => {
    setNavActive(false);
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 1200) {
        closeMenu();
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (window.innerWidth <= 1200) {
      closeMenu();
    }
  }, []);

  return (
    <nav className={`navbar ${navActive ? "active" : ""}`}>
      <div >
        <Link to="/" className="navbar--content" style={{ textDecoration: 'none' }}>
         <h3 className="navbar-item-name" >Pramuditha Karunarathna</h3>
        </Link>
      </div>
      <div className={`nav__hamburger ${navActive ? "active" : ""}`} onClick={toggleNav}>
        <span className="nav__hamburger__line"></span>
        <span className="nav__hamburger__line"></span>
        <span className="nav__hamburger__line"></span>
      </div>
      <div className={`navbar--items ${navActive ? "active" : ""}`}>
        <ul>
        <li>
            <Link to="/" className="navbar--content" onClick={closeMenu}>
              HOME
            </Link>
          </li>
          <li>
            <Link to="/about" className="navbar--content" onClick={closeMenu}>
              ABOUT
            </Link>
          </li>
          <li>
            <Link to="/projects" className="navbar--content" onClick={closeMenu}>
              PROJECTS
            </Link>
          </li>
          <li>
            <Link to="/contact" className="navbar--content" onClick={closeMenu}>
              CONTACT
            </Link>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;
