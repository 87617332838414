import { useState } from 'react';
import emailjs from 'emailjs-com';
import '../styles/Contact.css';

const Contact: React.FC = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        contactNo: '',
        message: ''
    });

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();

        emailjs.send(
            'service_k0uvgo5',  
            'template_q7b1yxm',
            formData,
            'GMfVEHC7b35kbtHM4'    
        )
        .then((response) => {
            console.log('Email sent successfully:', response);
            alert('Message sent successfully!');
            setFormData({
                name: '',
                email: '',
                contactNo: '',
                message: ''
            });
        })
        .catch((error) => {
            console.error('Error sending email:', error);
            alert('Failed to send message. Please try again later.');
        });
    };

    return (
        <div>
            <h2>Contact Me</h2>
            <div className="contact-main-container">
                <div className="contact-info-container">
                    <h3 className='contact-info-section-title'>Contact Info</h3>
                    <p id="para1">I’m here to help and would love to hear from you! For any questions or collaborations, please reach out using the contact details below or fill out the form.
                        <br/> Easy peasy!</p>
                    <div className='contact-info-item'>
                        <img src="/assets/address.png" alt="Address Icon" className='contact-info-img' />
                        <p>Kandy, Sri Lanka</p>
                    </div>
                    <div className='contact-info-item'>
                        <img src="/assets/email.png" alt="Email Icon" className='contact-info-img' />
                        <p>shemil076@gmail.com</p>
                    </div>
                    <div className='contact-info-item'>
                        <img src="/assets/phone.png" alt="Phone Icon" className='contact-info-img' />
                        <p>+94 764259425</p>
                    </div>
                </div>
                <div className="contact-form-container">
                    <h3 className='contact-form-section-title'>Get in touch</h3>
                    <form onSubmit={handleSubmit} className='contact-form-container'>
                        <div className='contact-form-item'>
                            {/* <label htmlFor="name">Name:</label> */}
                            <input
                                type="text"
                                id="name"
                                name="name"
                                value={formData.name}
                                onChange={handleChange}
                                required
                                placeholder=' Name'
                                className='input-field'
                            />
                        </div>
                        <div className='contact-form-item'>
                            {/* <label htmlFor="email">Email:</label> */}
                            <input
                                type="email"
                                id="email"
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                                required
                                placeholder=' Email'
                                className='input-field'
                            />
                        </div>
                        <div className='contact-form-item'>
                            {/* <label htmlFor="contactNo">Contact No:</label> */}
                            <input
                                type="text"
                                id="contactNo"
                                name="contactNo"
                                value={formData.contactNo}
                                onChange={handleChange}
                                required
                                placeholder=' Contact No'
                                className='input-field'
                            />
                        </div>
                        <div className='contact-form-item'>
                            {/* <label htmlFor="message">Message:</label> */}
                            <textarea
                                id="message"
                                name="message"
                                value={formData.message}
                                onChange={handleChange}
                                required
                                placeholder=' Message'
                                className='input-field'
                            />
                        </div>
                        <button type="submit" className='contact-send-button'>SEND</button>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default Contact;
